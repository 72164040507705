import { Padding } from "../attributes/padding";
import { Size } from "../attributes/size";
import { Widget } from "./widget";

type ContainerProps = {
    width?: Size;
    height?: Size;
    padding?: Padding;
    color?: number;
    child?: Widget;
};

export class Container extends Widget {
    constructor({width, height, padding, color, child}: ContainerProps) {
        super();
        this.width = width || Size.auto;
        this.height = height || Size.auto;
        this.color = color;
        this.child = child;
        this.padding = padding || Padding.zero;
        this.x = 0;
        this.y = 0;
    }
}