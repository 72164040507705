import { Color } from "../attributes/color";
import { Padding } from "../attributes/padding";
import { Size } from "../attributes/size";
import { log, logCalc, logCall } from "../utils/log";

export class Widget {
  width: Size;
  height: Size;
  color: Color|undefined;
  padding: Padding;
  x: number;
  y: number;
  child: Widget|undefined;
  children: Widget[]|undefined;

  public constructor() {
    this.width = Size.auto;
    this.height = Size.auto;
    this.color = undefined;
    this.padding = Padding.zero;
    this.x = 0;
    this.y = 0;
    this.child = undefined;
    this.children = undefined;
  }

  public layout(): void {
    logCall('layout', this.constructor.name);
    if (this.child) {
      this.child.x = this.x + this.padding.left;
      this.child.y = this.y + this.padding.top;
      if (!this.child.children)
        this.child.layout();
    }
    this.calcSize();
  }

  public calcSize(): void {
    logCall('calcSize', this.constructor.name);
    if (this.width.isAuto && this.child) {
      this.width.set(this.padding.horizontal + this.child.width.calculatedValue);
      logCalc('auto width', this.constructor.name, this.width.calculatedValue, this.child.constructor.name);
    }
    if (this.height.isAuto && this.child) {
      this.height.set(this.padding.vertical + this.child.height.calculatedValue);
      logCalc('auto height', this.constructor.name, this.height.calculatedValue, this.child.constructor.name);
    }
    if (this.child && this.child.width.isPercent) {
      this.child.width.set((this.width.calculatedValue - this.padding.horizontal) * (this.child.width.value / 100));
     logCalc('percent width', this.child.constructor.name, this.child.width.calculatedValue, this.constructor.name);
    }
    if (this.child && this.child.height.isPercent) {
      this.child.height.set((this.height.calculatedValue - this.padding.vertical) * (this.child.height.value / 100));
      logCalc('percent height', this.child.constructor.name, this.child.height.calculatedValue, this.constructor.name);
    }
    if (this.child?.children) {
      this.child.calcSize();
      this.child.layout();
    }
  }

  public render(width: number, height: number, data: Uint8ClampedArray): void {
    logCall('render', this.constructor.name);
    if (this.color) {
      for (let x = this.x; x < this.x + this.width.calculatedValue; x++) {
        for (let y = this.y; y < this.y + this.height.calculatedValue; y++) {
          const index = (x + y * width) * 4;
          data[index] = this.color.r;
          data[index + 1] = this.color.g;
          data[index + 2] = this.color.b;
          data[index + 3] = 0xff;
        }
      }
    }
    if (this.child) {
      this.child.render(width, height, data);
    } else if (this.children) {
      this.children.forEach((child) => child.render(width, height, data));
    }
  }

  public getChildren(): Widget[]|undefined {
    if (this.children) return this.children;
    if (this.child) return [this.child];
    return undefined;
  }
}
