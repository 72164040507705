export class Padding {
    top: number;
    right: number;
    bottom: number;
    left: number;

    constructor(top?: number, right?: number, bottom?: number, left?: number) {
        this.top = top || 0;
        this.right = right || 0;
        this.bottom = bottom || 0;
        this.left = left || 0;
    }

    static zero: Padding = new Padding();
    
    static symmetric(vertical: number, horizontal: number): Padding {
        return new Padding(vertical, horizontal, vertical, horizontal);
    }

    static all(value: number): Padding {
        return new Padding(value, value, value, value);
    }

    get horizontal(): number {
        return this.left + this.right;
    }

    get vertical(): number {
        return this.top + this.bottom;
    }
}