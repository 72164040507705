export const log = (message: string, types: number[]): void => {
    const time = new Date().toISOString().slice(11, -1);
    const styles = types.map(type => ({
        0: 'color: #a6a6a6',
        1: 'color: #619ede',
        2: 'color: #ffcc00',
        3: 'color: #9783fa',
    }[type]));
    console.log(`%c[${time}] ${message}`, 'color: #a6a6a6', ...styles);
};

export const logCalc = (type: string, ofWidget: string, value: number, fromWidget: string) : void => {
    // log(`%cCalculated %c${type} %cof %c${ofWidget} %cis %c${value}px%c from %c${fromWidget}`, [0, 3, 0, 2, 0, 1, 0, 2]);
};

export const logCall = (method: string, from: string) : void => {
    // log(`%cCalled %c${method} %con %c${from}`, [0, 3, 0, 2]);
};