import { Color } from "./attributes/color";
import { Padding } from "./attributes/padding";
import { Size } from "./attributes/size";
import { Spacing } from "./attributes/spacing";
import { Column } from "./widgets/column";
import { Container } from "./widgets/container";
import { Row } from "./widgets/row";
import { Widget } from "./widgets/widget";

type XmlProp = {
  name: string;
  value: string;
};

export class XentoUI {
  private width: number;
  private height: number;
  public screen?: Widget;

  public constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.screen = undefined;
  }

  public layout(): void {
    if (this.screen)
      this.screen.layout();
  }

  public render(): ImageData {
    if (!this.screen) throw new Error("No screen defined");
    const buffer = new ImageData(this.width, this.height);
    this.screen.render(this.width, this.height, buffer.data);
    return buffer;
  }

  public resize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  public parseLayout(xml: string): void {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, "text/xml");
    const root = doc.documentElement;
    this.screen = this.parseElement(root);
    console.log(this.screen);
  }

  private parseElement(element: any): Widget {
    const type = element.tagName.toLowerCase();
    const props: any = this.parseProps(element.attributes);
    if (element.children.length) {
      if (["row", "column"].includes(type)) {
        props.children = [];
        for (const child of element.children) {
          props.children.push(this.parseElement(child));
        }
      } else if (["container"].includes(type)) {
        props.child = this.parseElement(element.children[0]);
      } else {
        throw new Error(`Unknown element type: ${type}`);
      }
    }

    switch (type) {
      case "container":
        return new Container(props);
      case "row":
        return new Row(props);
      case "column":
        return new Column(props);
      default:
        throw new Error(`Unknown element type: ${type}`);
    }
  }

  private parseProps(props: XmlProp[]) {
    const result: any = {};
    for (const prop of props) {
      result[prop.name] = this.parseProp(prop);
    }
    return result;
  }

  private parseProp({ name, value }: XmlProp) {
    switch (name) {
      case "width":
      case "height":
        return new Size(value);
      case "spacing":
        return new Spacing(value);
      case "color":
        return new Color(value);
      case "padding":
        const values = value.split(",").map((v) => parseInt(v.trim()));
        if (values.length === 1)
          return Padding.all(values[0]);
        if (values.length === 2)
          return Padding.symmetric(values[0], values[1]);
        if (values.length === 3)
          return new Padding(values[0], values[1], values[2], values[1]);
        if (values.length === 4)
          return new Padding(values[0], values[1], values[2], values[3]);
        throw new Error(`Invalid padding value: ${value}`);
      default:
        return value;
    }
  }
}
