export enum SizeType {
    Auto = "auto",
    Pixels = "pixels",
    Percent = "percent",
}

export class Size {
    public readonly value: number;
    public calculatedValue: number;
    private readonly type: SizeType;

    public constructor(value?: string) {
        if (value === undefined || value.trim().length === 0) {
            this.value = 0;
            this.calculatedValue = this.value;
            this.type = SizeType.Auto;
        } else if (value.includes("%")) {
            const percent = value.replace("%", "").trim();
            this.value = parseInt(percent);
            this.calculatedValue = this.value;
            this.type = SizeType.Percent;
        } else {
            this.value = parseInt(value.trim());
            this.calculatedValue = this.value;
            this.type = SizeType.Pixels;
        }
    }

    public static auto: Size = new Size();

    public get isAuto(): boolean {
        return this.type === SizeType.Auto;
    }

    public get isPercent(): boolean {
        return this.type === SizeType.Percent;
    }

    public set(value: number): void {
        this.calculatedValue = Math.round(value);
    }
}
