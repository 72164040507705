export class Color {
    private readonly value: number;

    public constructor(value: string) {
        this.value = parseInt(value.replace('#', ''), 16);
    }

    public get r(): number {
        return this.value >> 16;
    }

    public get g(): number {
        return (this.value >> 8) & 255;
    }

    public get b(): number {
        return this.value & 255;
    }

    public get hex(): string {
        return '#' + this.value.toString(16).padStart(6, '0');
    }
}