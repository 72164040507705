import { Color } from "../attributes/color";
import { Padding } from "../attributes/padding";
import { Size } from "../attributes/size";
import { Spacing } from "../attributes/spacing";
import { logCalc, logCall } from "../utils/log";
import { Widget } from "./widget";

type ColumnProps = {
    width?: Size;
    height?: Size;
    padding?: Padding;
    spacing?: Spacing;
    color?: Color;
    children?: Widget[];
};

export class Column extends Widget {
    spacing: Spacing;

    public constructor({width, height, padding, spacing, color, children}: ColumnProps) {
        super();
        this.width = width || Size.auto;
        this.height = height || Size.auto;
        this.color = color;
        this.children = children || [];
        this.padding = padding || Padding.zero;
        this.spacing = spacing || Spacing.zero;
        this.x = 0;
        this.y = 0;
    }

    public layout() {
        logCall('layout', this.constructor.name);
        if (!this.children) return;

        const offsetTop = this.calcSpacing();                
        this.calcSize();

        for (let i = 0; i < this.children.length; i++) {
            const prevChildY = i > 0 ? this.children[i - 1].y : this.y + this.padding.top + offsetTop;
            const prevChildHeight = i > 0 ? this.children[i - 1].height.calculatedValue + this.spacing.value : 0;

            this.children[i].y = prevChildY + prevChildHeight;
            this.children[i].x = this.x + this.padding.left;

            this.children[i].layout();
        }
    }

    private calcSpacing(): number {
        logCall('calcSpacing', this.constructor.name);
        if (!this.children) return 0;

        let offsetTop = 0;
        if (!this.spacing.isPixels && this.height) {
            this.children.forEach(child => child.layout());
            const totalHeight = this.children.reduce((total, child) => total + child.height.calculatedValue, 0) + this.padding.vertical;
            offsetTop = this.spacing.calculate(this.height.calculatedValue, totalHeight, this.children.length);
        }
        return offsetTop;
    }

    public calcSize(): void {
        logCall('calcSize', this.constructor.name);
        if (!this.children) return;

        if (this.width.isAuto) {
            const maxChildWidth = this.children.reduce((maxWidth, child) => Math.max(maxWidth, child.width.calculatedValue), 0);
            this.width.calculatedValue = maxChildWidth + this.padding.left + this.padding.right;
            logCalc('auto width', this.constructor.name, this.width.calculatedValue, this.constructor.name);
        }

        if (this.height.isAuto) {
            const verticalPadding = this.padding.top + this.padding.bottom;
            const spacing = this.spacing.value * (this.children.length - 1);
            this.height.calculatedValue = this.children.reduce((height, child) => height + child.height.calculatedValue, 0) + verticalPadding + spacing;
            logCalc('auto height', this.constructor.name, this.height.calculatedValue, this.constructor.name);
        }

        for(const child of this.children) {
            if (child.width.isPercent) {
                child.width.set((this.width.calculatedValue - this.padding.horizontal) * (child.width.value / 100));
                logCalc('percent width', child.constructor.name, child.width.calculatedValue, this.constructor.name);
            }
            if (child.height.isPercent) {
                child.height.set((this.height.calculatedValue - this.padding.vertical) * (child.height.value / 100));
                logCalc('percent height', child.constructor.name, child.height.calculatedValue, this.constructor.name);
            }
        }
    }
}