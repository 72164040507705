export enum SpacingType {
    Pixels = 'pixels',
    Percent = 'percent',
    SpaceBetween = 'space-between',
    SpaceAround = 'space-around',
    SpaceEvenly = 'space-evenly',
}

export class Spacing {
    public readonly value: number;
    public calculatedValue: number;
    private readonly type: SpacingType;

    public constructor(value: string) {
        if (value.includes('%')) {
            const percent = value.replace('%', '').trim();
            this.value = parseInt(percent);
            this.calculatedValue = 0;
            this.type = SpacingType.Percent;
        } else if (['space-between', 'space-around', 'space-evenly'].includes(value)) {
            this.value = 0;
            this.calculatedValue = 0;
            this.type = value as SpacingType;
        } else {
            this.value = parseInt(value.trim());
            this.calculatedValue = this.value;
            this.type = SpacingType.Pixels;
        }
    }

    public static zero: Spacing = new Spacing('0');

    public get isPixels(): boolean {
        return this.type === SpacingType.Pixels;
    }

    public get isPercent(): boolean {
        return this.type === SpacingType.Percent;
    }

    public get isSpaceBetween(): boolean {
        return this.type === SpacingType.SpaceBetween;
    }

    public get isSpaceAround(): boolean {
        return this.type === SpacingType.SpaceAround;
    }

    public get isSpaceEvenly(): boolean {
        return this.type === SpacingType.SpaceEvenly;
    }

    public calculate(mainAxisSize: number, childrenSize: number, childrenCount: number): number {
        if (this.type === SpacingType.Pixels) {
            throw new Error('Cannot calculate pixels');
        }
        const freeSpace = mainAxisSize - childrenSize;
        if (this.type === SpacingType.Percent) {
            this.calculatedValue = Math.round((this.value / 100) * freeSpace);
            return this.calculatedValue;
        }
        if (this.type === SpacingType.SpaceBetween) {
            this.calculatedValue = Math.round(freeSpace / (childrenCount - 1));
            return 0;
        }
        if (this.type === SpacingType.SpaceAround) {
            this.calculatedValue = Math.round(freeSpace / childrenCount);
            return Math.round(this.calculatedValue / 2);
        }
        if (this.type === SpacingType.SpaceEvenly) {
            this.calculatedValue = Math.round(freeSpace / (childrenCount + 1));
            return this.calculatedValue;
        }
        return 0;
    }
}