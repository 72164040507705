import { Color } from "../attributes/color";
import { Padding } from "../attributes/padding";
import { Size } from "../attributes/size";
import { Spacing } from "../attributes/spacing";
import { logCalc, logCall } from "../utils/log";
import { Widget } from "./widget";

type RowProps = {
    width?: Size;
    height?: Size;
    padding?: Padding;
    spacing?: Spacing;
    color?: Color;
    children?: Widget[];
};

export class Row extends Widget {
    public spacing: Spacing;

    public constructor({width, height, padding, spacing, color, children}: RowProps) {
        super();
        this.width = width || Size.auto;
        this.height = height || Size.auto;
        this.color = color;
        this.children = children || [];
        this.padding = padding || Padding.zero;
        this.spacing = spacing || Spacing.zero;
        this.x = 0;
        this.y = 0;
    }

    public layout() {
        logCall('layout', this.constructor.name);
        if (!this.children) return;

        const offsetLeft = this.calcSpacing();

        for (let i = 0; i < this.children.length; i++) {
            const prevChildX = i > 0 ? this.children[i - 1].x : this.x + this.padding.left + offsetLeft;
            const prevChildWidth = i > 0 ? this.children[i - 1].width.calculatedValue + this.spacing.value : 0;

            this.children[i].x = prevChildX + prevChildWidth;
            this.children[i].y = this.y + this.padding.top;

            this.children[i].layout();
        }
    }

    private calcSpacing(): number {
        logCall('calcSpacing', this.constructor.name);
        if (!this.children) return 0;

        let offsetLeft = 0;
        if (!this.spacing.isPixels && !this.width.isAuto) {
            this.children.forEach(child => child.layout());
            const totalWidth = this.children.reduce((total, child) => total + child.width.calculatedValue, 0) + this.padding.horizontal;
            offsetLeft = this.spacing.calculate(this.width.calculatedValue, totalWidth, this.children.length);
        }
        return offsetLeft;
    }

    public calcSize(): void {
        logCall('calcSize', this.constructor.name);
        if (!this.children) return;

        if (this.width.isAuto) {
            const horizontalPadding = this.padding.left + this.padding.right;
            const spacing = this.spacing.value * (this.children.length - 1);
            this.width.set(this.children.reduce((width, child) => width + child.width.calculatedValue, 0) + horizontalPadding + spacing);
            logCalc('auto width', this.constructor.name, this.width.calculatedValue, this.constructor.name);
        }

        if (this.height.isAuto) {
            const maxChildHeight = this.children.reduce((maxHeight, child) => Math.max(maxHeight, child.height.calculatedValue), 0);
            this.height.set(maxChildHeight + this.padding.top + this.padding.bottom);
            logCalc('auto height', this.constructor.name, this.height.calculatedValue, this.constructor.name);
        }

        for(const child of this.children) {
            if (child.width.isPercent) {
                child.width.set((this.width.calculatedValue - this.padding.horizontal) * (child.width.value / 100));
                logCalc('percent width', child.constructor.name, child.width.calculatedValue, this.constructor.name);
            }
            if (child.height.isPercent) {
                child.height.set((this.height.calculatedValue - this.padding.vertical) * (child.height.value / 100));
                logCalc('percent height', child.constructor.name, child.height.calculatedValue, this.constructor.name);
            }
        }
    }
}