import { XentoUI } from "./xento_ui";
import JSONWorker from 'url:monaco-editor/esm/vs/language/json/json.worker.js';
import CSSWorker from 'url:monaco-editor/esm/vs/language/css/css.worker.js';
import HTMLWorker from 'url:monaco-editor/esm/vs/language/html/html.worker.js';
import TSWorker from 'url:monaco-editor/esm/vs/language/typescript/ts.worker.js';
import EditorWorker from 'url:monaco-editor/esm/vs/editor/editor.worker.js';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.main.js';

const canvasContainer = document.getElementById("canvas_container");
const codeContainer = document.getElementById("container");
const canvas = document.getElementById("canvas");
const ctx = canvas.getContext("2d");

const width = canvas.width;
const height = canvas.height;

const ui = new XentoUI(width, height);

const render = (xml) => {
  let canRender = true;
  try {
    ui.parseLayout(xml);
    ui.layout();
  } catch (e) {
    canRender = false;
    console.error(e);
  }
  if (!canRender) return;
  const buffer = ui.render();
  ctx.putImageData(buffer, 0, 0);
};

const layout = `
<Container
  color="#cccccc"
  width="$screenWidth"
  height="$screenHeight"
  padding="10,0,0,10">
    <Row
      width="50%"
      height="50%"
      color="#ef4444">
      <Container
        width="50%"
        height="50%"
        color="#4444ef" />
      <Container
        width="25%"
        height="50%"
        color="#44efef" />
    </Row>
</Container>
`.trim();

self.MonacoEnvironment = {
	getWorkerUrl: function (moduleId, label) {
		if (label === 'json') {
			return JSONWorker;
		}
		if (label === 'css' || label === 'scss' || label === 'less') {
			return CSSWorker;
		}
		if (label === 'html' || label === 'handlebars' || label === 'razor') {
			return HTMLWorker;
		}
		if (label === 'typescript' || label === 'javascript') {
			return TSWorker;
		}
		return EditorWorker;
	}
};

const editor = monaco.editor.create(codeContainer, {
	value: layout,
  theme: "vs-dark",
	language: 'xml',
  automaticLayout: true,
});

editor.onDidChangeModelContent((e) => {
  render(editor.getValue().replace("$screenWidth", canvas.width).replace("$screenHeight", canvas.height));
});

window.addEventListener("resize", () => {
  canvas.width = canvasContainer.offsetWidth;
  canvas.height = canvasContainer.offsetHeight;
  ui.resize(canvas.width, canvas.height);
  render(editor.getValue().replace("$screenWidth", canvas.width).replace("$screenHeight", canvas.height));
});

canvas.width = canvasContainer.offsetWidth;
canvas.height = canvasContainer.offsetHeight;
render(layout.replace('$screenWidth', canvas.width).replace('$screenHeight', canvas.height));